import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MyCerts = ({userData}) =>{
    const navigate = useNavigate()


    const handleViewCert = (courseData, date=null, certificateCode = "NIL") =>{
        navigate('/cert', {
            state: {
              userData: userData,
              courseData: courseData,
              date: new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
              certCode: certificateCode
            }
          });
    }
    
    
    return(
        <>
            <div className="row">
                <h3 className="text-dark my-3 fw-bold"><i className="fa fa-certificate text-warning"></i> {userData.name} Certificates <i className="fa fa-certificate text-warning"></i></h3>
            </div>
            <Table bordered hover>
                <thead>
                <tr>
                    <th>S/N</th>
                    <th>Course Title</th>
                    <th>Certificate</th>
                </tr>
                </thead>
                <tbody>
                    {userData.cert_gotten.length === 0 && <tr><td colSpan={3}><center>No Certificate yet...<br/> Complete your program to view your certificat</center></td></tr>}
                    {userData.cert_gotten.length >0 && userData.cert_gotten.map((item, index) => (
                        <tr key={index}>
                            {/*console.log(JSON.stringify(item,null,2))*/}
                        <td>{index + 1}</td>
                        <td>{item.courseId.title}</td>
                        <td>
                            <button onClick={()=>handleViewCert(item.courseId,item.gottenAt,item.certCode)} target="_blank" rel="noopener noreferrer">
                            View Certificate
                            </button>
                        </td>
                        </tr>
                    ))}                                                     
                </tbody>
            </Table>
        </>
    )
}

export default MyCerts